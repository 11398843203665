/* Roboto (Google Fonts) */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

/* Root Definitions */
:root {
  /* Colors */
  --spm-white: #ffffff;
  --spm-black: #000000;
  --spm-grey: #787878;
  --spm-light-grey: #b3abab;
  --spm-light-background: #eeeeee;
  --spm-secondary: #e58918;
  --spm-header-footer-color: #f1f1f1;

  /* Adjusted Naming Convention */
  --cs-grey: rgb(197, 197, 197);
  --cs-input-grey: #c6c6c6;
  --cs-very-light-grey: #fafafa;
  --cs-blue: rgb(47, 110, 195);
  --cs-price-box: #f1f1f1;
  --cs-price-box-grey: #747474;

  /* Primary Color */
  --spm-primary: #fe9000;

  /* Primary Hover Color */
  --spm-hover-color: rgb(97, 148, 214);

  /* Font Sizing */
  --cs-fontsize-h1: 1.9rem;
  --cs-fontsize-h2: 1.4rem;
  --cs-fontsize-h3: 1rem;
  --cs-fontsize-h4: 2rem;
  --cs-fontsize-h5: 1.5rem;
  --cs-fontsize-h6: 0.9rem;

  --tooltip-font-size: 0.8rem;

  /* Font Weights */
  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  /* Font Styles */
  --font-style-normal: normal;
  --font-style-italic: italic;

  /* Fonts */
  --font-primary: "Inter", sans-serif;

  /* Heights */
  --header-height: 60px;
  --footer-height: 3.3rem;

  /* Tints and Shadows */
  --spm-tint-color: rgba(0, 0, 0, 0.3);
  --spm-shadow-color: rgba(0, 0, 0, 0.2);
  --spm-light-shadow-color: rgba(0, 0, 0, 0.05);
}

/* Basic Reset */
html {
  font-size: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-primary);
}

/* Universal Text Defaults */
body,
input,
footer,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
label,
em {
  line-height: 1.6;
  font-family: var(--font-primary);
  font-style: var(--font-style-normal);
  color: var(--base-font-color);
  letter-spacing: 1px;
  text-align: center;
}

label {
  font-size: var(--cs-fontsize-h2);
  font-weight: var(--font-weight-medium);
}

/* Heading Styles */
h1 {
  font-size: var(--cs-fontsize-h1);
  font-weight: var(--font-weight-medium);
}
h2 {
  font-size: var(--cs-fontsize-h2);
  font-weight: var(--font-weight-medium);
}
h3 {
  font-size: var(--cs-fontsize-h3);
  font-weight: var(--font-weight-regular);
}
h4 {
  font-size: var(--cs-fontsize-h4);
  font-weight: var(--font-weight-regular);
}
h5 {
  font-size: var(--cs-fontsize-h2);
  font-weight: var(--font-weight-regular);
}
h6,
footer,
a {
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
}

button {
  font-size: var(--cs-fontsize-h5);
  font-weight: var(--font-weight-thin);
  background-color: var(--cs-blue);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

a:hover {
  color: var(--spm-hover-color);
}

button:hover {
  background-color: var(--spm-hover-color);
}

input,
select,
textarea,
button {
  outline: none;
}

hr {
  border: none;
  background-color: var(--cs-input-grey);
  height: 1px;
  margin: 10px 0;
}

input {
  border: 1px solid var(--cs-input-grey);
  border-radius: 5px;
}

input::placeholder {
  color: var(--spm-light-grey);
  font-weight: var(--font-weight-thin);
}

/* Animation Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
